"use client";

import { Metadata } from "next";
import Error from "@/pages/Error";
export const metadata: Metadata = {
  title: "Упс... Что-то пошло не так",
  description: "Упс... Что-то пошло не так",
  openGraph: {
    title: "Упс... Что-то пошло не так",
    description: "Упс... Что-то пошло не так"
  }
};
export default function Custom500({
  error
}: {
  error: Error;
}) {
  return <Error error={error} data-sentry-element="Error" data-sentry-component="Custom500" data-sentry-source-file="error.tsx" />;
}